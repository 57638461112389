import React, { useEffect, useState } from 'react';
import {  useDispatch, useSelector } from "react-redux";
import { Redirect, Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Spinner } from 'reactstrap';
import { GlobalError } from '../Components/Messages/GlobalError';
import { LoginForm } from '../Components/Form';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { directLogIn } from '../store/actions';
import { FacebookIcon, GoogleIcon } from '../Components/Icons';
import { AccountCreatedMessage } from '../Components/Account/AccountCreatedMessage';

import { useGoogleLogin } from '@react-oauth/google';


import './LoginPage.css';
import AuthService from '../Services/AuthService';
import { getLanguage } from '../store/sagas/selectors';
import { clearRefIdentifier, getRefIdentifier, globalErrorHandling, setFacebookLogIn, setGoogleLogin, setToken } from '../shared/utils';
import { useMixpanel } from '../contexts/Mixpanel';
import { LOGIN_SUBMIT } from '../shared/EventsNames';

import { PublicTemplate } from '../Templates/PublicTemplate';

import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import { Helmet } from 'react-helmet';
import UserService from '../Services/UserService';
import { logger } from '../shared/Logger';

const LoginPage = (props) => {

  const loggedIn = useSelector(state => state.security.loggedIn);
  const language = useSelector(getLanguage);
  const redirectToLogin = useSelector(state => state.user.createAccount.redirectToLogin);

  const [auth, setAuth] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [googleData, setGoogleData] = useState();
  const [facebookData, setFacebookData] = useState();
  const [social, setSocial] = useState();
  const [error, setError] = useState();
  const [latestEmail, setLatestEmail] = useState();
  const [isRedirect, setIsRedirect] = useState(redirectToLogin);

  const dispatch = useDispatch();
  const intl = useIntl();
  const mixpanel = useMixpanel();
  const { search } = useLocation();

  const processSocialAuth = (type, token, serviceMethod) => {
    mixpanel.track(LOGIN_SUBMIT, {"with": type});
    
    setLoading(true);

    const request = {
      "token": token,
      "language": language,
      "analyticsId": mixpanel.get_distinct_id()
    };

    const ref_id = getRefIdentifier();
    if (ref_id !== undefined) {
      request.refId = ref_id;
    }

    serviceMethod(request).then(response => {
      setSocial(type);
      setAuth(response.data);
      clearRefIdentifier();
    }).catch(err => {
        const errorObj = globalErrorHandling(err);

        let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
        if (msg === errorObj.errorKey) {
          msg = intl.formatMessage({ id: "login.msg.error"});
        }
        
        dispatch(errorNotification({
          message: msg,
          position: 'tc',
          autoDismiss: 0,
        }));

    }).finally(() => {
      setLoading(false);
    })

  }

  useEffect(() => {

    if (googleData) {
      processSocialAuth("Google", googleData.access_token, AuthService.authWithGoole);
    }

  }, [googleData]);

  useEffect(() => {

    if (facebookData) {
      processSocialAuth("Facebook", facebookData.accessToken, AuthService.authWithFacebook);
    }

  }, [facebookData]);

  useEffect(() => {

    if (auth) {
      setToken(auth.token);
      dispatch(directLogIn(auth.token));
    }

  }, [auth]);

  useEffect(() => {

    if (social) {
      if (social === "Google") {
        setGoogleLogin();
      } else if (social === "Facebook") {
        setFacebookLogIn();
      }
      
    }

  }, [social]);

  useEffect(() => {
    if (redirectToLogin) {
      setTimeout(() => setIsRedirect(!redirectToLogin), 30000)
    }
  }, [])


  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => setGoogleData(response),
    onError: (error) => setGoogleData(undefined)
  });

  const handleFacebookLogin = (fbData) => {

    if (fbData.accessToken !== undefined) {
      setFacebookData(fbData);
    }

  }

  const handleSubmit = (model) => {

    mixpanel.track(LOGIN_SUBMIT);

    setSubmitting(true);

    AuthService.auth(model.email, model.password)
    .then(response => {
      setAuth(response.data);
    }).catch(err => {
      
      const errorObj = globalErrorHandling(err);

      let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
      if (msg === errorObj.errorKey) {
        errorObj.errorKey = "login.msg.error";
      }
      
      setError(errorObj);
      setLatestEmail(model.email);

    }).finally(() => {
      setSubmitting(false);
    });

  }

  const handleActivationClick = (e) => {

    e.preventDefault();

    UserService.activationEmail(latestEmail)
    .then(response => {
      
      switch (response.data.result) {
        case "sent":
          dispatch(successNotification({
            message: intl.formatMessage({ id: "message.global.activationEmailSent"}),
            position: 'tc',
            autoDismiss: 0,
          }));
          break;
        case "activated":
          dispatch(errorNotification({
            message: intl.formatMessage({ id: "message.global.userActivatedBefore"}),
            position: 'tc',
            autoDismiss: 0,
          }));
          break;
        default:
          break;
      }

      setError(null);

    }).catch(err => {
      logger.error(err);
    });
    
  }

  if (loggedIn === true || auth) {
      return (
        <Redirect to={{
          pathname: "/ai",
          search: search
        }} />
      );
  }

    let errorContent = null;

    if (error?.errorCode === 210) {

      errorContent = (
        <div>
          <FormattedMessage id="message.global.clickToActivationEmail" values={{link: (
            <a href="#requestActivationEmail" onClick={handleActivationClick} className="alert-link">
              <FormattedMessage id="global.words.here" />
            </a>
          )}} />
        </div>
      );
    }

    const redirectContent = 
      <div className="px-4 py-2 bg-primary rounded-lg">
        <AccountCreatedMessage soMeCampaign={false} className={'mb-2'}/>
      </div>


    const content = 
      <>
        <div className="pb-3">
            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_ID}
                fields="name,email"
                callback={handleFacebookLogin}
                render={renderProps => {
                  return (
                    <Button id='btnLogInFacebook' color="primary" block className='pl-4' onClick={renderProps.onClick} disabled={loading || submitting}>
                      <div className='d-flex align-items-center'>
                        <FacebookIcon height='23px' width='23px' />
                        <span className='flex-grow-1 text-center'><FormattedMessage id="login.facebook" /></span>
                      </div>
                    </Button>
                    );
                }}
            />
        </div>

        <div className="pb-3">
          <Button id='btnLogInGoogle' color="primary" block onClick={handleGoogleLogin} className="pl-4" disabled={loading || submitting}>
            <div className='d-flex align-items-center'>
              <GoogleIcon height='22px' width='22px' />
              <span className='flex-grow-1 text-center'><FormattedMessage id="login.google" /></span>
            </div>
          </Button>
        </div>

        <div className="px-4 py-2 bg-primary rounded-lg">

          <div className="text-white d-flex mb-3">
            <i className="far fa-envelope" />
            <div className="flex-grow-1 text-center">
              <FormattedMessage id="login.email.title" />
            </div>
          </div>

          <LoginForm id="login-form" onSubmit={handleSubmit} disabled={loading || submitting} />

          <div className='d-flex justify-content-center'>
            <button id='btnLogIn' className="btn btn-accent-2 pl-4 pr-4 font-weight-bold" form="login-form" disabled={loading || submitting}>
                <FormattedMessage id="login.btn" />
                {submitting?<Spinner size="sm" className="ml-2"/>:null}
            </button>
          </div>
        </div>

        <div className="mt-2 d-flex justify-content-between">
            <div>
              <FormattedMessage id="login.createAccount"/>&nbsp;
              <Link data-test="link-to-create-account" to="/create-account" className="accent-link">
                  <FormattedMessage id="login.createAccount.link"/>
              </Link>
            </div>
            <Link to="/forgot-password" className="accent-link" data-test="link-to-forgot-password">
              <FormattedMessage id="login.forgotPassword"/>
            </Link>
        </div>
      </>

    return (
       <PublicTemplate>
        <Helmet>
          <title>Zerocopy - {intl.formatMessage({id: "login.title"})}</title>
        </Helmet>

        <div className='login-page'>
          <GlobalError error={error} extraContent={errorContent}/>
          {isRedirect ? redirectContent : content}
        </div>
      </PublicTemplate>
    );

}

export default LoginPage;
