import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';
import { saveProfile } from '../../store/actions';
import { sectionShape } from '../../Shapes';
import Field from './Field'

class Section extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      fieldsValues: {},
      fieldError: false,
    };

    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.validateFields = this.validateFields.bind(this);
  }

  handleNextClick(e) {
    e.preventDefault();

    if (this.validateFields()) {

      const finalStep = this.isFinalStep();

      if (this.props.onSave) {
        this.props.onSave(this.props.section.id, this.state.fieldsValues, finalStep);
      } else {
        this.props.saveProfile(this.props.section.id, this.state.fieldsValues, finalStep);
      }
    }
  }

  componentDidMount() {

    if (this.props.previousChoices !== undefined) {

      const prevChoicesForSection = this.props.previousChoices[this.props.section.id];

      if (prevChoicesForSection !== undefined) {

        const newValues = {};

        Object.keys(prevChoicesForSection).forEach(fieldId => {

          newValues[fieldId] = [];

          prevChoicesForSection[fieldId].forEach(choice => {
            newValues[fieldId].push(choice.id);
          });

        });

        this.setState({
          fieldsValues: newValues,
        });
      }

    }
  }

  validateFields() {
    const filledFields = Object.keys(this.state.fieldsValues)

    // Check if all the fields have minimum number of selections
    if (this.props.section.fields.findIndex( field => 
      (filledFields[field.id] === undefined && field.minChoices > 0) // no choices in field and minimum choices are not 0 
      || (filledFields[field.id] !== undefined && filledFields[field.id].length < field.minChoices) ) // some choices in field but less than minimum choices 
      === -1) { 

      this.setState({ fieldError: true});

      return false;
    }

    this.setState({ fieldError: false});

    return true;
  }

  isFinalStep() {
    return this.props.index === this.props.totalSections - 1;
  }

  handleFieldChange = (newFieldValue, fieldId) => {

    if (( typeof newFieldValue === "string" && newFieldValue === "")
          || (Array.isArray(newFieldValue) && newFieldValue.length === 0)
          || newFieldValue === undefined || newFieldValue === null) {
      delete this.state.fieldsValues[fieldId];
    } else {
      this.setState(prevState => ({
          fieldsValues: {
              ...prevState.fieldsValues,
              [fieldId]: newFieldValue.length?newFieldValue.map(v => v.id):[newFieldValue.id]
          }
      }));
    }
  }

  render () {

    const { section, index, currentIndex, totalSections, previousChoices, submitting, lang, reward } = this.props;

    const active = index === currentIndex;
    const finalStep = this.isFinalStep();

    if (!active) {
      return null;
    }

    let defaultValues = [];
    if (previousChoices !== undefined && previousChoices[section.id] !== undefined) {
      defaultValues = previousChoices[section.id];
    }

    let sectionTitle;

    if (section.hasOwnProperty(`label${lang}`)) {
      sectionTitle = section[`label${lang}`];
    } else {
      sectionTitle = section.labelEn;
    }


    return (
      <form>
        <h4>{sectionTitle}</h4>
        <div className={this.state.fieldError?"alert alert-danger":"d-none"}>
          <FormattedMessage id="profile.section.error.fillFields"/>
        </div>
        {section.fields.map(f =>
          <Field field={f} key={f.id} onChangeHandler={this.handleFieldChange} defaultValues={defaultValues[f.id]} disabled={submitting} />
        )}
        <div className="modal-footer">
          <div className="w-100">
            <FormattedMessage id="profile.section.step" values={{current: index + 1, total: totalSections}} />
          </div>
          <button type="button" className="btn btn-sm btn-primary" onClick={this.handleNextClick} disabled={submitting} data-test={`btn-fill-profile-${finalStep ? "finish": "next"}`}>
              <FormattedMessage id={`profile.section.btn.${finalStep ? "finish": "next"}`} />
              {submitting?<Spinner size="sm" className="ml-2"/>:null}
            </button>
        </div>
      </form>
    );
  }

}

Section.propTypes = {
  index: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  totalSections: PropTypes.number.isRequired,
  section: sectionShape.isRequired,
  reward: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  return {
    previousChoices: state.profile.previousChoices,
  };
}

const mapDispatchToProps = { saveProfile }

export default connect(mapStateToProps, mapDispatchToProps)(Section);
