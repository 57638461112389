import moment from "moment";

export const tempNotificationConfig = [
  {
    "key": "european_elections_2024",
    "one_time": true,
    "start_date": "01/06/2024",
    "end_date": "09/06/2024",
    "picture": "https://zerocopy.ams3.digitaloceanspaces.com/images/temp_notifications/Flag_of_Europe.svg",
    "title": "temp.notification.title",
    "content": "temp.notification.content"
  },
  {
    "key": "ai_tutor_mvp",
    "one_time": true,
    "start_date": "05/06/2024",
    "end_date": "01/11/2024",
    "title": "temp.notification.ai_tutor_mvp.title",
    "content": "temp.notification.ai_tutor_mvp.content",
    "toggle": false
  },
];

const temp_notification_prefix = "zc_temp_notification-"

export const getActiveNotification = () => {
  const now = moment();
  return tempNotificationConfig
            .filter(tn => !localStorage?.getItem(temp_notification_prefix + tn.key)) // Remove already hidden notifications
            .find(tn => now.isBetween(moment(tn.start_date, 'DD/MM/YYYY'), moment(tn.end_date, 'DD/MM/YYYY'), 'day', "[]"));
}

export const hideOneTimeNotification = (notification) => {
  localStorage?.setItem(temp_notification_prefix + notification.key, "1");
}
