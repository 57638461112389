import React, { useState, useEffect, useRef } from 'react';
import { Carousel, CarouselItem, Fade } from 'reactstrap';
import { useAds } from '../../contexts/AdsContext';
import { AdsService } from '../../Services';
import './Billboard.css';

const AdLink = ({ad, onClick}) => {

  const handleOnClick = () => {

    onClick(ad.id);
  };

  return (
    <a href={ad.clickUrl} target="_blank" rel="noopener noreferrer" onClick={handleOnClick} onAuxClick={handleOnClick}>
      <img src={ad.src} loading='lazy' alt={ad.altText} className="billboard-image" />
    </a>
  );
};

const Billboard = ({show, style = {}}) => {
  const [animating, setAnimating] = useState(false);
  const {billboardAds, currentBillboardAdIndex, changeBillboardAd, default_timeout_seconds} = useAds();
  const [ ad, setAd ] = useState();
  const adRef = useRef();
  const [ hasBeenSeen, setHasBeenSeen ] = useState(false);


  useEffect(() => {

    if (ad && !hasBeenSeen) {
      handleNewView(ad.id);
    }

  }, [ ad ]);

  useEffect(() => {

    if (default_timeout_seconds && default_timeout_seconds > 0) {
      const firstNext = setTimeout(next, default_timeout_seconds * 1000);
      return () => {
        clearInterval(firstNext);
      }
    }

  }, [default_timeout_seconds]);

  const handleOnClick = () => {
    AdsService.addClick(null, ad.id);
  }

  const handleNewView = (id) => {
    setHasBeenSeen(true);
    AdsService.addView(null, id);
  }

  const onExiting = () => {
    setAnimating(true);
  }

  const onExited = () => {
    setAnimating(false);
  }

  const onEnter = () => {
    setAd(billboardAds[currentBillboardAdIndex]);
  }

  const next = () => {
    if (animating) return;
    changeBillboardAd(true);
    setHasBeenSeen(false);
  }

  const previous = () => {
    if (animating) return;
    changeBillboardAd(false);
    setHasBeenSeen(false);
  }

  if (show === false || !billboardAds || billboardAds.length < 1) {
    return (
      <div className="billboard">
        
      </div>
    );
  }

  const slides = billboardAds.map((ad, index) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} onEnter={onEnter} key={index}
        className="text-center">
        <a href={ad.clickUrl} ref={adRef} target="_blank" rel="noopener noreferrer" onClick={handleOnClick} onAuxClick={handleOnClick}>
          <img src={ad.src} loading='lazy' alt={ad.altText} className="billboard-image" />
        </a>
      </CarouselItem>
    );
  });

  return (
    <Fade in={true}>
      <div className="billboard" style={style}>
        <div className="m-auto billboard-image-container">
          <Carousel activeIndex={currentBillboardAdIndex} next={next} previous={previous} interval={default_timeout_seconds * 1000}
            pause={false} ride="carousel" className='carousel-fade'>
            {slides}
          </Carousel>
        </div>
      </div>
    </Fade>
  );

}


export default Billboard;
