import React, { useEffect, useState } from "react";
import { getActiveNotification, hideOneTimeNotification } from "./temp_notification_config";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";

const TempNotification = () => {

  const [notification, setNotification] = useState();
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    
    if (!notification) {
      const current = getActiveNotification();
      if (current) {
        setNotification(current);
        setOpen(true);
      }
    }
    
  }, [notification]);

  useEffect(() => {

    if (!open) {
      setNotification(null);
      if (notification?.one_time === true) {
        hideOneTimeNotification(notification);
      }
    }

  }, [open]);

  if (!notification) {
    return null;
  }

  const toggle = () => {
    if (notification.toggle !== false) {
      setOpen(!open);
    }
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Modal isOpen={open} toggle={toggle} centered data-test="temp-notification">
      { notification.title ? <ModalHeader toggle={notification.toggle !== false ? toggle : null}>{intl.formatMessage({id: notification.title})}</ModalHeader> : null }
      <ModalBody>
        <Container fluid>
          <Row>
            { notification.picture ? <Col xs={{size: 8, offset: 2}} sm={{size: 4, offset: 0}}><img style={{width: "100%"}} src={notification.picture} /></Col> : null }
            <Col className="mt-3 mt-sm-0" xs="12" sm={notification.picture ? 8 : 12}>
              {intl.formatMessage({id: notification.content})}
            </Col>
          </Row>
        </Container>
      </ModalBody>
      {notification.toggle === false && (
        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            <FormattedMessage id="btn.close" />
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );

}

export default TempNotification;
