import React, { useEffect, useState } from 'react';
import { DocumentSelect } from '../DocumentSelect'
import { AiCard } from '../AiCard'
import { LoadingScreen } from '../LoadingScreen'
import { Grid, Chip, IconButton } from '@material-ui/core';
import { DocumentPreview } from '../../../Components/Documents/DocumentPreview';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useMixpanel } from '../../../contexts/Mixpanel';
import './AiTutorPage.css'
import { AI_USER_GENERATE, AI_USER_GENERATE_ERROR, AI_USER_PREVIEW_DOCUMENT, AI_USER_SELECT_DOCUMENT, AI_USER_WAIT_LOADING_END } from '../../../shared/EventsNames';
import FlashcardService from '../../Services/FlashcardService';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '../../../hooks/CustomHooks';
import { Error } from '../../../Components/Messages/Error'
import ConfigurationService, { SPENDING_LIMIT_REACHED } from '../../Services/ConfigurationService';
import { logger } from '../../../shared/Logger';
import QuestionService from '../../Services/QuestionService';
import { KeyboardBackspaceRounded } from '@material-ui/icons';
import '../AiTutor.css'

export const AI_FLASHCARDS = 'flashcards'
export const AI_QUIZ = 'quiz'

const cardStyle = {    
  "borderRadius": '0.9rem',
  "boxShadow": "0 0 1em 0 #00b4ff",
}

const defaultConfig = {
  selectedDoc: null,
  type: AI_FLASHCARDS,
  qNumber: 20,
  pageRange: [1, 100],
  questionTypes: {
    multipleChoice: true,
    trueFalse: false,
    gapFill: false,
    definition: false,
  },
  name:"",
}

const requestErrorMsgId = "ai.request.error"
const docErrorMsgId = "ai.document.error"

const AiTutorPage = ({type}) => {
  
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const history = useHistory();
  const docId = useQuery().get("docId");

  const isSmall = useMediaQuery('(max-width:768px)');
  const [enabled, setEnabled] = useState(true);
  const [config, setConfig] = useState(defaultConfig)
  const [maxPages, setMaxPages] = useState(100)
  const [loading, setLoading] = useState(false) 
  const [preview, setPreview] = useState(false)
  const [error, setError] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [settingsClasses, setSettingsClasses] = useState("")
  const [validDocs, setValidDocs] = useState(true);
  const [errorMsgId, setErrorMsgId] = useState("ai.request.error");
  const intl = useIntl();

  const hidePreviewSettingsClasses = 'content-slide-back'

  const showPreviewSettingsClasses = "content-slide-right"

  const [generationKey, setGenerationKey] = useState();

  useEffect(() => {

    ConfigurationService.fetchConfig(SPENDING_LIMIT_REACHED)
      .then(response => {
        setEnabled(response.data === "false" || response.data === false);
      }).catch(error => {
        logger.error("Error checking AI Speniding limit", error);
      });

  }, []);

  useEffect(() => {

    setConfig( {
      ...config,
      type: type,
    } )

  }, [type]);

  useEffect(() => {

    if (generationKey) {
      setError(false)

      const handlePoolingFlashcards = () => {

        FlashcardService.fetch(generationKey)
          .then(response => {

            if (response.data.status === "ERROR") {
              mixpanel.track(AI_USER_WAIT_LOADING_END, {"key": generationKey, "docId": config.selectedDoc.id, "generationConfig": config})
              setError(true)
              setLoading(false)
              setGenerationKey(null);
            }
            else if (response.data.status === "GENERATED") {
              mixpanel.track(AI_USER_WAIT_LOADING_END)
              setGenerationKey(null);
              setLoading(false)
              history.push(`/ai/flashcards/${response.data.id}`);
            }

          }).catch(error => {
            logger.error("Error fetching flashcard generation with key:" + generationKey, error);
            setErrorMsgId(requestErrorMsgId)
            setError(true)
            setLoading(false)
            setGenerationKey(null);
          });

      };

      const handlePoolingQuiz = () => {

        QuestionService.fetch(generationKey)
          .then(response => {

            if (response.data.status === "ERROR") {
              mixpanel.track(AI_USER_WAIT_LOADING_END, {"key": generationKey, "docId": config.selectedDoc.id, "generationConfig": config})
              setError(true)
              setLoading(false)
              setGenerationKey(null);
            }
            else if (response.data.status === "GENERATED") {
              mixpanel.track(AI_USER_WAIT_LOADING_END)
              setGenerationKey(null);
              setLoading(false)
              history.push(`/ai/quiz/${response.data.id}`);
            }

          }).catch(error => {
            logger.error("Error fetching quiz generation with key:" + generationKey, error);
            setError(true)
            setLoading(false)
            setGenerationKey(null);
          });

      };

      const intervalId = setInterval(type === AI_FLASHCARDS ? handlePoolingFlashcards : handlePoolingQuiz, 5000);
      return () => clearInterval(intervalId);

    }

  }, [generationKey]);

  const handleGenerate = () => {
    mixpanel.track(AI_USER_GENERATE, config)
    setLoading(true);
    setGenerationKey(null);

    if (config.type === AI_FLASHCARDS) {
      FlashcardService.create(config)
        .then(response => handleGenerationResponse(response))
        .catch(error =>  handleGenerationError(error));
    }
    else if (config.type === AI_QUIZ) {
      QuestionService.create(config)
        .then(response => handleGenerationResponse(response))
        .catch(error =>  handleGenerationError(error));

    }
  }

  const handleGenerationResponse = (response) => {
    setGenerationKey(response.data);
  }

  const handleGenerationError = (error) => {
    logger.error("Error sending request to generate AI content with config:" + config, error);
    setLoading(false);
    let propsToTrack = {
      "http_status": error.response.status,
    };

    if (error.response.data?.errors) {
      propsToTrack["errors"] = error.response.data.errors;
    }

    mixpanel.track(AI_USER_GENERATE_ERROR, propsToTrack);
  }

  const handleDocSelected = (selected) => {
    if (selected != null) {
      if (selected.hash == null) {
        setErrorMsgId(docErrorMsgId)
        setError(true)
        return;
      }
      setError(false)
      mixpanel.track(AI_USER_SELECT_DOCUMENT, selected)
      setMaxPages(selected.numberOfPages)
      setConfig( { 
        ...config,
        selectedDoc: selected,
        pageRange: [1, selected.numberOfPages], 
      } )
      setMaxPages(selected.numberOfPages)
    }
  }

  const handleQNumberChange = (number) => {
    setConfig( {
      ...config,
      qNumber: number,
    } )
  }

  const handlePageRangeChange = (range) => {
    setConfig( {
      ...config,
      pageRange: range,
    } )
  }

  const handleValidDocsChange = (valid) => {
    setValidDocs(valid) 
  }

  const handleQuestionTypeChange = (questions) => {
    setConfig( {
      ...config,
      questionTypes: questions,
    })
  }

  const handleNameChange = (value) => {
    setConfig( {
      ...config,
      name: value,
    })
  }

  const handleShowPreview = () => {
    setPreview(!preview)
    if (preview) {
      mixpanel.track(AI_USER_PREVIEW_DOCUMENT, {type: config.type, document: config.selectedDoc })
    }
    if (!isSmall) {
      if (preview) {
        setTimeout(() => setShowPreview(false), 100)
        setSettingsClasses(hidePreviewSettingsClasses)
      }
      if (!preview) {
        setSettingsClasses(showPreviewSettingsClasses)
        setTimeout(() => setShowPreview(true), 100)
      }
    }
    else {
      setShowPreview(!preview)
    }
  }

  let docPreview  
  if (config.selectedDoc !== null) {
    if (isSmall) {
      docPreview =  
        <DocumentPreview url={config.selectedDoc?.previewUrl.substring(0, config.selectedDoc?.previewUrl.length - 4) + '.source.pdf'} isModal={true} isOpen={preview} toggle={handleShowPreview}  />
    }
    else {
      docPreview =  
      <Grid item xs={8} md={6} lg={6} className={`${preview ? 'slide-in' : 'slide-out'}`}> 
          <DocumentPreview style={cardStyle} url={config.selectedDoc?.previewUrl.substring(0, config.selectedDoc?.previewUrl.length - 4) + '.source.pdf'} isModal={false} isOpen={false} toggle={handleShowPreview} />
      </Grid>
    }
  }

  return (
    <div style={{width: "100%", height: "100%"}} className="d-flex justify-content-center">
    <div className='d-flex justify-content-center ai-container'>
        {!enabled ? <DisabledMessage /> :
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <div className='text-center'>
                    <p className='fs-2 font-weight-bold mb-0 mt-2'>
                      <FormattedMessage id={`ai.page.heading.${type}`}/>
                      <span>&nbsp;</span>
                      <Chip label="BETA" color="secondary"/> 
                    </p>
                  </div>
                </Grid>
                { error ? 
                <Grid item xs={12}  className={'d-flex justify-content-center'}>
                  <Error errorMessage={intl.formatMessage({id:errorMsgId})}/>
                </Grid> : null}
                <Grid item xs={12}>
                  <DocumentSelect docId={docId} handleSelected={handleDocSelected} handleValidDocs={handleValidDocsChange} />
                </Grid>
                <Grid item xs={12} >
                  <Grid container spacing={1} justifyContent='center' className='mt-2 p-1' >
                    <Grid item xs={12}  md={!showPreview ? 12 : 6 } lg={!showPreview ? 12 : 6 } className={`${settingsClasses}`} 
                       style={{transition: theme.transitions.create("all", {
                              easing: theme.transitions.easing.easeIn, 
                              duration: theme.transitions.duration.standard,
                        }), display: "flex", justifyContent: "center" }} 
                      >

                      { validDocs && 
                      <AiCard type={config.type} 
                        doc={config.selectedDoc} 
                        generate={handleGenerate}
                        maxPages={maxPages} 
                        changeQNumber={handleQNumberChange} 
                        changePageRange={handlePageRangeChange}
                        changeQuestionTypes={handleQuestionTypeChange}
                        changeName={handleNameChange}
                        showPreview={handleShowPreview}
                        preview={preview}
                      />
                      }
                    </Grid> 
                    {showPreview ? docPreview : null}
                  </Grid> 
                </Grid> 
              </Grid>
              <LoadingScreen isOpen={loading}/>
            </>
      }
          </div>
        </div>
          
  );
}

export default AiTutorPage;

const DisabledMessage = () => {
  const intl = useIntl();
  return (
    <Error errorMessage={intl.formatMessage({id:"ai.disabled"})}/>
  );

}
