import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Error } from '../Messages/Error';
import { useGlobal } from "../../contexts/GlobalContext";
import { isNumberOfPagesOverMaximum, isReadyForDelivery  } from '../../shared/utils';

const DeliveryWarningMessage = (props) => {

  const docs = useSelector(state => state.documents.list);
  const selected = useSelector(state => state.documents.selected);
  const user = useSelector((state) => state.user.user);
  const freeLimit = useSelector((state) => state.system.credit_policy_user_print_limit);

  const intl = useIntl();

  // Calculations
  const hasCreditsToPrint = useMemo(() => selected?.pages !== 0 && selected?.pages <= user?.printCredits, [selected, user]);

  let errorText = null;
  let extraContent = null;

  if (selected?.documents.length > 0 && (user?.printsForAcademicYear + selected?.pages + selected?.ads) > freeLimit) {
    const remainingPages = freeLimit - user.printsForAcademicYear;
    errorText = intl.formatMessage({id: "documents.msg.selectionOverFreeLimit"}, {pages: remainingPages});
  } else if (selected?.pages && hasCreditsToPrint === false) {
    errorText = intl.formatMessage({id: "deliveries.request.message.error.noCredits"});
  } else if (isReadyForDelivery(docs, selected?.documents) === false) {
    errorText = intl.formatMessage({id: "deliveries.request.message.warning.notReady"});
  } else {
    return null;
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <Error errorMessage={errorText} extraContent={extraContent}/>
      </div>
    </div>
  );
}

export default DeliveryWarningMessage;
